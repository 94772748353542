<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">电子电气架构开发</h1>
        <p data-caption-delay="100" class="wow fadeInRight">可实现整车功能架构、网络架构、物理架构的开发支持</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros electric-solution-intro wow fadeInLeft">
      <div class="container">
        <div class="normal-title">解决方案介绍</div>
        <div class="all-intro">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
              <div class="intro-text">
                <p>东信创智基于现行的CAN/CANFD/LIN/FlexRay/Ethernet 国际标准、成熟的技术、丰富的项目经验，开发整车电子电气架构平台。</p>
              </div>
              <div class="intro-text">
                <p>开发内容包括：需求分析、功能定义、子系统定义、软件架构定义、网络与诊断开发、整车电气原理设计等。</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
              <div class="intro-picture">
                <img src="../../../assets/images/solution-electric-intro.png" alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="left-bg">
        <img src="../../../assets/images/index-about-bg-01.png" alt="">
      </div>
      <div class="right-bg">
        <img src="../../../assets/images/index-about-bg-02.png" alt="">
      </div>
    </section>
    <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div
            class="all-function"
            @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
            @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
        >
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in functionList" :key="index">
              <div class="card-item">
                <el-card class="solution-function-card" shadow="hover">
                  <div class="card-icon">
                    <img :src=" item.icon " alt="">
                  </div>
                  <div class="card-text">
                    <p>{{ item.content }}</p>
                  </div>
                </el-card>
              </div>
            </swiper-slide>
            <!-- 根据情况引入控件 -->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <section class="electric-solution-components wow fadeInLeft">
      <div class="container">
        <div class="normal-title">核心组件</div>
        <div class="solution-components">
          <el-row class="components-content" :gutter="40">
            <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
              <img src="../../../assets/images/solution-electric-components-pic.png" alt="">
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
              <div class="components-text">
                <div>
                  <h1>网络架构开发(CAN/CANFD/LIN/FlexRay/Ethernet)</h1>
                  <ol>
                    <li>CAN/LIN/FlexRay/Ethernet网络需求规范开发、通讯数据库和网关路由表开发</li>
                    <li>OSEK/AUTOSAR网络管理需求规范开发及整车睡眠唤醒策略定义</li>
                    <li>诊断开发，包括UDS On CAN/LIN、DoIP等诊断需求规范开发、诊断数据库开发、 BootLoader需求规范、安全访问算法等</li>
                    <li>以太网协议簇，包括SOME/IP、DDS、AVB/TSN、RTP等</li>
                  </ol>
                </div>
                <div style="margin-top: 16px">
                  <h1>功能架构开发</h1>
                  <ol>
                    <li>功能规范开发包括function list定义、功能设计需求规范(FDR)、功能实现规范(FR)、子系统功能规范(SSTS)等</li>
                    <li>SOA服务抽象及服务接口定义</li>
                  </ol>
                </div>
                <div style="margin-top: 16px">
                  <h1>物理架构开发</h1>
                  <ol>
                    <li>整车网络拓扑设计及优化</li>
                    <li>整车电气原理设计</li>
                    <li>整车电源分配及接地点设计</li>
                  </ol>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
import functionPic1 from '@/assets/images/solution-electric-function-icon-1.svg'
import functionPic2 from '@/assets/images/solution-electric-function-icon-2.svg'
import functionPic3 from '@/assets/images/solution-electric-function-icon-3.svg'
import functionPic4 from '@/assets/images/solution-electric-function-icon-4.svg'
export default {
  name: "Architecture",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-1',
      functionList: [
        {
          icon: functionPic1,
          content: '覆盖整车网络架构、功能架构和物理架构的工程服务'
        },
        {
          icon: functionPic2,
          content: '成熟量产级的需求规范定义'
        },
        {
          icon: functionPic3,
          content: '基于AUTOSAR的通讯系统设计'
        },
        {
          icon: functionPic4,
          content: '基于MBD的正向功能开发设计能力'
        },
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "电子电气架构开发咨询服务 - 解决方案 - 东信创智";
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
